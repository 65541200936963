<template>
  <div>
    <inner-page-title title="City Center Shuttle"></inner-page-title>

    <div class="map-container">
      <div ref="google_map" style="height: 400px;"></div>
    </div>

    
    
    <div class="container route-information">
      
      <div class="direction-picker-container">
        <el-select v-model="direction" placeholder="Direction" style="width: 200px; margin-right: 5px; flex: 1;">
          <el-option label="To the office" value="go"></el-option>
          <el-option label="Home" value="back"></el-option>
        </el-select>

        <el-button @click="$router.push('/shuttle-live-info/sddfdsfdsf')" type="secondary">View realtime</el-button>
      </div>

      <h4 style="margin-bottom: 10px;">Timetable</h4>
      <el-table
        :data="timetableData"
        border
        highlight-current-row
        style="width: 100%">
        <el-table-column
          prop="stop_name"
          label="Stop"
          min-width="180">
        </el-table-column>
        <el-table-column
          prop="time_one"
          label="07:00"
          width="70">
        </el-table-column>
        <el-table-column
          prop="time_two"
          label="08:00"
          width="70">
        </el-table-column>
        <el-table-column
          prop="time_three"
          label="08:15"
          width="70">
        </el-table-column>
      </el-table>
    </div>


    <button class="floating-button" @click="modal_active = true;">Book</button>

    <modal v-model="modal_active" title="Book shuttle">
      <mobility-booking v-loading="loading_modal" @onBook="onBook"></mobility-booking>
    </modal>
  </div>
</template>

<script>
import initGoogleMaps from '@/utils/gmaps';
import Modal from '@/components/Modal/Modal';
import MobilityBooking from '@/components/MobilityBooking';

export default {
  components: { Modal, MobilityBooking },
  props: {
    id: { type: String }
  },

  data: () => ({
    publicPath: process.env.BASE_URL,
    direction: "go",
    realtime: true,
    timetableData: [
      {
        stop_name: "D’Olier Street",
        time_one: "07:00",
        time_two: "08:00",
        time_three: "08:15"
      },
      {
        stop_name: "St. Stephen Green East",
        time_one: "07:03",
        time_two: "08:03",
        time_three: "08:18"
      },
      {
        stop_name: "Leeson Street Bridge",
        time_one: "07:04",
        time_two: "08:04",
        time_three: "08:19"
      },
      {
        stop_name: "Morehampton Road",
        time_one: "07:07",
        time_two: "08:07",
        time_three: "08:22"
      },
      {
        stop_name: "Opposite Donnybrook Fire Station",
        time_one: "07:10",
        time_two: "08:10",
        time_three: "08:25"
      },
      {
        stop_name: "Donnybrook Church",
        time_one: "07:12",
        time_two: "08:12",
        time_three: "08:27"
      },
      {
        stop_name: "Stillorgan Road",
        time_one: "07:15",
        time_two: "08:15",
        time_three: "08:30"
      },
      {
        stop_name: "Brewery Road",
        time_one: "07:20",
        time_two: "08:20",
        time_three: "08:35"
      },
      {
        stop_name: "Central Park",
        time_one: "07:30",
        time_two: "08:35",
        time_three: "08:50"
      }
    ],
    waypoints: [
      {
        location: "St. Stephen Green East, Dublin",
        stopover: true
      },
      {
        location: "Leeson Street Bridge, Dublin",
        stopover: true
      },
      {
        location: "Morehampton Road, Dublin",
        stopover: true
      },
      {
        location: "Donnybrook Fire Station, Dublin",
        stopover: true
      },
      {
        location: "Donnybrook Church, Dublin",
        stopover: true
      },
      {
        location: "Brewery Road, Dublin",
        stopover: true
      }
    ],
    modal_active: false,
    loading_modal: false
  }),

  async mounted() {
    let google = await initGoogleMaps();
    let directionsService = new google.maps.DirectionsService;
    let directionsRenderer = new google.maps.DirectionsRenderer;

    this.map = new google.maps.Map(this.$refs.google_map, {
      zoom: 16,
      center: { lat: 53.26, lng: -6.26 },
      // mapTypeId: 'satellite',
      disableDefaultUI: true
    });

    directionsRenderer.setMap(this.map);

    directionsService.route({
      origin: "D’Olier Street, Dublin",
      destination: "Central Park, Sandyford",
      waypoints: this.waypoints,
      optimizeWaypoints: true,
      travelMode: 'DRIVING'
    }, function(response, status) {
      if (status === 'OK') {
        directionsRenderer.setDirections(response);
      }
    });
  },

  methods: {
   onBook(data) {
     console.log(data);

     this.loading_modal = true;

     setTimeout(() => {
       this.loading_modal = false;
       this.modal_active = false;

       setTimeout(() => {
         this.$router.push('/shuttle-live-info/sddfdsfdsf');
       }, 300);
     }, 1250);
   }
  }
}
</script>

<style lang="scss" scoped>
  .route-information {
    padding-bottom: 65px;
  }

  .direction-picker-container {
    display: flex;
    margin-bottom: 15px;
  }
</style>

